import gql from "graphql-tag"

export const JapaneseRegister = gql`
  mutation createJapaneseform($input: createJapaneseformInput) {
    createJapaneseform(input: $input) {
      japaneseform {
        FullName
        Email
        Country
        NativeLanguage
        WorkID
        WorkConditions
        form_type {
          FormType
        }
        PrevRegister
        IpAddress
        IpCountry
      }
    }
  }
`
export const MexicanRegister = gql`
  mutation createMexicanform($input: createMexicanformInput) {
    createMexicanform(input: $input) {
      mexicanform {
        FullName
        Email
        State
        CitizenshipCountry
        NativeLanguage
        WorkID
        WorkConditions
        form_type {
          FormType
        }
        PrevRegister
        IpAddress
        IpCountry
      }
    }
  }
`

export const OtherRegister = gql`
  mutation createOtherform($input: createOtherformInput) {
    createOtherform(input: $input) {
      otherform {
        FullName
        Email
        Country
        CitizenshipCountry
        NativeLanguage
        WorkID
        WorkCondition
        form_type {
          FormType
        }
        PrevRegister
        IpAddress
        IpCountry
      }
    }
  }
`
export const NewRegister = gql`
  mutation createShinsotsuform($input: createShinsotsuformInput) {
    createShinsotsuform(input: $input) {
      shinsotsuform {
        FullName
        Email
        Country
        Graduated
        GraduationTime
        WorkConditions
        form_type {
          FormType
        }
        PrevRegister
      }
    }
  }
`
// Mutation For contact US form

export const NewContact = gql`
  mutation createContact($input: createContactInput) {
    createContact(input: $input) {
      contact {
        FullName
        Email
        PhoneNumber
        QueryContent
        IpAddress
        IpCountry
      }
    }
  }
`

// Mutation for contact Company
export const NewEmployer = gql`
  mutation createEmployer($input: createEmployerInput) {
    createEmployer(input: $input) {
      employer {
        CompanyName
        JobTitle
        FullName
        Email
        PhoneNumber
        Description
        IpAddress
        IpCountry
      }
    }
  }
`

// Mutation for Recruit
export const NewRecruit = gql`
  mutation createRecruit($input: createRecruitInput) {
    createRecruit(input: $input) {
      recruit {
        FullName
        Email
        PhoneNumber
        Country
        Motivation
      }
    }
  }
`

//Survey
export const SurveyForm = gql`
  mutation createSurvey($input: createSurveyInput) {
    createSurvey(input: $input) {
      survey {
        FullName
        Email
        PhoneNumber
        State
        Country
        ReasonsToWorkOnMexico
        History
        Period
        CountryName
        Purpose
        SpanishLevel
        SpanishQualifications
        OtherLanguage
      }
    }
  }
`
//LpMex
export const LpMexRegister = gql`
  mutation Lpmexform($input: createLpmexformInput) {
    createLpmexform(input: $input) {
      lpmexform {
        FullName
        Email
        CitizenshipCountry
        NativeLanguage
        Query
        IpAddress
        IpCountry
      }
    }
  }
`

//Lp Page
export const LpPageRegister = gql`
mutation createLpform($input: createLpformInput) {
  createLpform(input: $input) {
    lpform {
      FullName
      Email
      Country
      Message
      IpAddress
      IpCountry
    }
  }
}
`
//Lp 02 Page
export const LpPage02Register = gql`
mutation createLp2form($input: createLp2FormInput) {
  createLp2Form(input: $input) {
    lp2Form {
      FullName
      Email
      Country
      Request
      IpAddress
      IpCountry
    }
  }
}
`

//Posts 
export const Posting = gql`
mutation createPost($input: createPostInput) {
  createPost(input: $input) {
    post{
      post_category{
        postCategory
      }
      title
      description
      nickname
      email
    }
  }
}
`

